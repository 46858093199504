<template>
  <div>
    <div class="login usr">
      <!--h3><img class="logo" src="@/img/logo.png" alt="インテリジェントヘルスケア ストレスチェックApp"></h3-->
      <h3 class="uk-text-center">{{ $store.state.common.sScreenTitle }}</h3>
      <div v-show="$store.state.common.sIsLoading">Loading...</div>

      <div v-show="!$store.state.common.sIsLoading">
        <!-- 準備中 -->
        <div v-if="$store.state.answer.sScStatusForScreen == 1">
          <div class="r_answer">
            <template v-if="flg">
              <p class="large cntr fwb" v-if="flg == true">
                {{ vScreenOnPreparation.sc_yearmonth }} 実施予定
              </p>
              <p class="large cntr fwb" v-else>
                現在実施予定のものはありません
              </p>
              <dl>
                <dt>実施期間：</dt>
                <dd>
                  {{ vScreenOnPreparation.sc_answer_start_date }} ～
                  {{ vScreenOnPreparation.sc_answer_end_date }}
                </dd>
                <dt>回答形式：</dt>
                <dd>
                  {{ vScreenOnPreparation.sc_conf_answer_name }}：
                  <p v-show="vScreenOnPreparation.sc_conf_answer == 1">
                    実施期間中にマークシートの回答を塗りつぶし、担当者へ提出してください。
                  </p>
                  <p v-show="vScreenOnPreparation.sc_conf_answer == 0">
                    実施期間中にストレスチェックシステムへログインし、回答ページよりアンケートにご回答ください。
                  </p>
                </dd>
                <dt>結果通知形式：</dt>
                <dd>
                  {{ vScreenOnPreparation.sc_conf_notice_name }}：
                  <p v-show="vScreenOnPreparation.sc_conf_answer == 1">
                    実施期間終了後に、アンケートの回答結果を分析し、インテリジェントヘルスケア株式会社が作成するストレスチェック分析結果用紙をお送りします。
                  </p>
                  <p v-show="vScreenOnPreparation.sc_conf_answer == 0">
                    実施期間終了後に、アンケートの回答結果を分析します。分析終了後結果確認ページよりご確認ください。
                  </p>
                </dd>
              </dl>
            </template>
            <template v-else>
              <h3 class="uk-text-center">ストレスチェック実施予定</h3>
              <p class="large cntr fwb">現在実施予定のものはありません</p>
            </template>
            <button type="button" class="uk-button uk-button-secondary uk-width-1-1" @click="doGoRespondentHome">
              &lt;&nbsp;戻る
            </button>
          </div>
        </div>

        <!-- 実施中 -->
        <div v-if="$store.state.answer.sScStatusForScreen == 3">
          <!-- Step1 -->
          <div class="r_answer">
            <div v-show="vAnswerStep == 1">
              <template v-if="flg">
                <p class="large cntr fwb">
                  {{ vScreenOnGoing.sc_yearmonth }}<br />ストレスチェック実施中
                </p>
                <dl>
                  <dt>アンケート終了日</dt>
                  <dd>{{ vScreenOnGoing.sc_answer_end_date }}</dd>
                  <dt>回答提出日</dt>
                  <span v-if="vAnswerStatus == 2">
                    <dd>提出済み {{ vAnswerCompDate }}</dd>
                  </span>
                  <span v-else>
                    <dd>未提出</dd>
                  </span>
                  <dt>アンケート</dt>
                  <dd>
                    質問は全57問あります。<br />
                    回答は途中保存されます。実施期間内に全ての質問にお答えください。<br />
                    <em>※</em>
                    全ての質問にお答えいただけない場合、正確な分析結果が得られない可能性があります。<br />
                    <em>※</em>
                    ブラウザの戻るボタンは使用しないでください。<br />
                  </dd>
                </dl>
                <button v-if="vAnswerAllDone" :disabled="$store.state.common.sServerProcessing" type="button"
                  class="uk-button uk-button-secondary uk-width-1-1" @click="doDisplayAnswerStep2">
                  回答確認
                </button>
                <template v-else>
                  <button :disabled="$store.state.common.sServerProcessing" type="button"
                    class="uk-button uk-button-secondary uk-width-1-1" @click="doDisplayAnswerStep2">
                    回答する
                  </button>
                </template>
                <button type="button" class="uk-button uk-button-secondary uk-width-1-1" @click="doGoRespondentHome">
                  &lt;&nbsp;戻る
                </button>
              </template>
              <template v-else>
                <h3 class="uk-text-center">ストレスチェック</h3>
                <p class="large cntr fwb">実施中のものはありません</p>
                <button type="button" class="uk-button uk-button-secondary uk-width-1-1" @click="doGoRespondentHome">
                  &lt;&nbsp;戻る
                </button>
              </template>
            </div>
          </div>

          <!-- Step2 -->
          <div v-show="vAnswerStep == 2">
            <div v-for="ca in vCategoryDataForAnswer" :key="ca.category_id">
              <p class="qmidashi" v-html="ca.question_text"></p>
              <p class="cntr">
                最もあてはまるものを選択してください<br />（&nbsp;{{
        vQuestionIndex + 1
      }}
                / {{ vQuestionDataForResult.length }}&nbsp;）
              </p>
              <div v-for="qu in vQuestionDataForAnswer" :key="qu.question_id">
                <p class="large150 cntr fwb">
                  問{{ qu.question_id }}.<br />
                  <span v-html="qu.question_text"></span>
                </p>
                <div v-for="(cp, cpIdx) in vChoicePatternForDisplay" :key="cp.choice_id" class="answerbtn">
                  <div v-if="qu.choice_id > 0 &&
        cpIdx + 1 == qu.choice_id &&
        vChoiceIndex < 0
        ">
                    <button :disabled="$store.state.common.sServerProcessing" type="button"
                      class="operate-button answerbtn_now" @click="doChoiceAnswer(cpIdx)">
                      <!--span class="uk-badge" >済</span><br/-->
                      <span v-html="cp.choice_text"></span>
                    </button>
                  </div>
                  <div v-if="qu.choice_id > 0 &&
        cpIdx + 1 != qu.choice_id &&
        vChoiceIndex < 0
        ">
                    <button :disabled="$store.state.common.sServerProcessing" type="button" class="operate-button"
                      @click="doChoiceAnswer(cpIdx)">
                      <span v-html="cp.choice_text"></span>
                    </button>
                  </div>
                  <div v-if="vChoiceIndex >= 0 && vChoiceIndex == cpIdx">
                    <!-- 選択時? -->
                    <button :disabled="$store.state.common.sServerProcessing" type="button" class="
                        operate-button-choice-answer
                        answerbtn_now answerbtn_choice
                      ">
                      <b><span v-html="cp.choice_text"></span></b>
                    </button>
                  </div>
                  <div v-if="vChoiceIndex >= 0 && vChoiceIndex != cpIdx">
                    <!-- 選択時の全体の挙動? -->
                    <button :disabled="$store.state.common.sServerProcessing" type="button" class="operate-button">
                      <span v-html="cp.choice_text"></span>
                    </button>
                  </div>
                  <div v-if="vChoiceIndex < 0 && !qu.choice_id">
                    <button :disabled="$store.state.common.sServerProcessing" type="button" class="operate-button"
                      @click="doChoiceAnswer(cpIdx)">
                      <span v-html="cp.choice_text"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="answer_foot">
              <div v-if="vQuestionIndex > 0">
                <button :disabled="$store.state.common.sServerProcessing" type="button" class="
                    operate-button
                    uk-button uk-button-secondary uk-width-1-1
                  " @click="doBackToPreviousQuestion">
                  &lt;&nbsp;前の質問
                </button>
              </div>
              <div v-else>
                <button :disabled="$store.state.common.sServerProcessing" type="button" class="
                    operate-button
                    uk-button uk-button-secondary uk-width-1-1
                  " @click="doChangeAnswerStep(1)">
                  &lt;&nbsp;戻る
                </button>
              </div>
              <!-- <div >
            <button :disabled="$store.state.common.sServerProcessing" type="button" class="operate-button" style="width:100%; line-height:3; background-color:#1e90ff; font-size:1.5em; color:#ffffff; border-radius:10px; border:solid 1px #696969;" @click="doSwitchDisplayMode">
            回答状況
            </button>
            </div> -->
            </div>
          </div>

          <!-- Step3 -->
          <div v-show="vAnswerStep == 3" class="answercheck">
            <div class="answercheck_btm">
              <button v-if="vAnswerStatus == 2" :disabled="$store.state.common.sServerProcessing" type="button"
                class="operate-button" @click="doGoRespondentHome">
                &lt;&nbsp;戻る
              </button>
              <!-- <button v-else :disabled="$store.state.common.sServerProcessing" type="button" class="operate-button" @click="doSwitchDisplayMode">
              回答を修正する
            </button> -->
              <div v-if="vAnswerAllDone && vAnswerStatus == 1">
                <button :disabled="$store.state.common.sServerProcessing" type="button"
                  class="operate-button uk-button uk-button-secondary" @click="doSubmitAnswer">
                  回答を提出する
                </button>
              </div>
            </div>
            <!-- <p v-if="vAnswerAllDone" class="cntr">
              <strong class="large">全{{ vQuestionDataForResult.length }}問回答済み</strong><br />
              <span v-show="vAnswerStatus == 2">回答提出済み</span>
              <span v-show="vAnswerStatus != 2"><em>回答未提出</em></span>
            </p> -->
            <div v-if="vAnswerAllDone" class="cntr">
              <div>
                <strong class="large">全{{ vQuestionDataForResult.length }}問回答済み</strong><br />
              </div>
              <div style="margin-top:10px;">
                <span v-show="vAnswerStatus == 2">回答提出済み</span>
                <span v-show="vAnswerStatus != 2"
                  style="font-weight:bold; font-size:2em; margin-top:10px;"><em>回答未提出</em>
                </span>
              </div>
              <div v-show="vAnswerStatus != 2"
                style="border:dashed 2px black; margin-top: 20px; margin-bottom: 20px; padding: 10px;">
                <div class="blink">
                  <div style="display:flex; justify-content:center; align-items: center; margin-bottom:10px;">
                    <div
                      style="background-color:black; color:white; font-weight:bold; padding:10px; width:40%; margin-right:5px;">
                      回答を提出する
                    </div>
                    をクリックするまで
                  </div>
                  <div style="display:flex; justify-content:center; align-items: center;">
                    <div style="color:red; font-weight:bold;">未回答扱い</div>
                    となります。
                  </div>
                </div>
              </div>
            </div>
            <p v-else-if="!vAnswerAllDone">
              現在の回答状況 （ 回答済み：{{ vLatestQuestionIndex }} / 全{{
        vQuestionDataForResult.length
      }}問 ）
            </p>
            <div>
              <div v-for="ca in vCategoryDataForResult" :key="ca.category_id">
                <p class="qmidashi" v-html="ca.question_text"></p>
                <div v-for="qu in vQuestionDataForResult" :key="qu.question_id">
                  <div v-if="ca.category_id == qu.category_id">
                    <div :id="qu.category_id + '_' + qu.question_id" class="answer_quest">
                      <p>{{ qu.question_id }}&nbsp;{{ qu.question_text }}</p>
                    </div>
                    <!-- <button v-if="qu.choice_id > 0" :disabled="$store.state.common.sServerProcessing" type="button" class="operate-button"  @click="doOpenAnswerStep3Modal(qu.category_id, qu.question_id)">
                  <b><span v-html="qu.choice_text"></span></b>
                  </button>
                  <button v-else :disabled="$store.state.common.sServerProcessing" type="button" class="operate-button"  @click="doOpenAnswerStep3Modal(qu.category_id, qu.question_id)">
                  未回答
                  </button> -->
                    <button v-if="qu.choice_id > 0" :disabled="$store.state.common.sServerProcessing" type="button"
                      class="answer_answer" @click="
        doReChoiceAtAnswerList(qu.category_id, qu.question_id)
        ">
                      <span v-html="qu.choice_text"></span>
                    </button>
                    <button v-else :disabled="$store.state.common.sServerProcessing" type="button" class="answer_answer"
                      @click="
        doReChoiceAtAnswerList(qu.category_id, qu.question_id)
        ">
                      未回答
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 回答提出後 -->
          <div v-if="vAfterSubmit">
            <h3 class="uk-text-center">回答が送信されました</h3>
            <p>
              ご協力ありがとうございました。<br />
              ストレスチェックの結果は、アンケート回答期間終了後に結果確認ページにてご確認いただけます。<br />
              アンケートの分析完了までもうしばらくお待ちください。
            </p>
            <button :disabled="$store.state.common.sServerProcessing" type="button"
              class="operate-button uk-button uk-button-secondary" @click="doGoRespondentHome">
              ホームへ
            </button>
          </div>
        </div>
        <!-- 回答一覧内モーダル -->
        <!-- <div :id="vModalIdForAnswer" uk-modal>
      <div class="uk-modal-dialog uk-width-auto" >
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-body" uk-overflow-auto>
      <div v-for="qu in vQuestionDataForModal" :key="qu.question_id">
      <div >
      <b>{{ qu.question_id }}.{{ qu.question_text }}</b>
      </div>
      <div >
      <div v-for="cp in vChoicePatternForModal" :key="cp.choice_id" >
      <div >
      <button :disabled="$store.state.common.sServerProcessing" type="button" class="operate-button"  @click="doChoiceAnswerAtModal(cp.choice_id)">
      <div v-if="qu.choice_id == cp.choice_id">
      <span class="uk-badge" >済</span><br/>
      <span v-html="cp.choice_text"></span>
      </div>
      <div v-else>
      <span v-html="cp.choice_text"></span>
      </div>
      </button>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import constant from "@/constant.js";
import UIkit from "uikit";

export default {
  data() {
    return {
      tmp: null,
      vComId: this.$store.state.common.sComId,
      vEmpCode: this.$store.state.common.sUserCode,
      vScId: this.$store.state.answer.sScId,

      vQuestionListData: [], // 質問情報
      vChoicePatternListData: [], // 選択肢情報
      vCategoryDataForAnswer: [], // 質問カテゴリ（回答用）
      vQuestionDataForAnswer: [], // 質問（回答用）
      vCategoryDataForResult: [], // 質問カテゴリ（一覧確認用）
      vQuestionDataForResult: [], // 質問（一覧確認用）
      vChoicePatternForDisplay: [], // 選択肢（表示用）

      vAnswerStatus: 0, // 回答状態
      vAnswerCompDate: "", // 回答完了年月日
      // vAnswerCount: 0,
      vAfterSubmit: false, // 回答提出直後判定（回答ありがとうメッセージ表示する用）
      vAnswerStep: 1, // 実施中画面のステップ（1:導入、2:アンケート、3:回答一覧確認）

      vCategoryId: 0, // カテゴリID（表示制御用）
      vQuestionId: 0, // 質問ID（表示制御用）
      vQuestionIndex: 0, // 質問インデックス
      vChoiceId: 0, // 選択肢id
      vChoiceIndex: -1, // 選択肢ボタンの外観制御のためのもの
      // vNowQuestionIndex: 0, // 現在表示している質問index

      vLatestCategoryId: 0, // 最終到達質問カテゴリid
      vLatestQuestionId: 0, // 最終到達質問id
      vLatestQuestionIndex: 0, // 最終到達質問index
      vAnswerAllDone: false, // 全て回答済み判定
      // vAbleSubmission: false, // 回答提出可能判定
      // vCountChoicedAnswer: 0, // 回答数

      flg: false,

      vScreenOnPreparation: {
        // 準備中用
        sc_yearmonth: "",
        sc_answer_start_date: "",
        sc_answer_end_date: "",
        sc_conf_answer: "",
        sc_conf_answer_name: "",
        sc_conf_notice: "",
        sc_conf_notice_name: "",
      },
      vScreenOnGoing: {
        // 実施中用
        sc_id: "",
        sc_yearmonth: "",
        sc_answer_end_date: "",
      },
      vMessage: "",
      // vModalIdForAnswer: 'modal-confirm-respondent-answer',
      // vQuestionDataForModal: [],
      // vChoicePatternForModal: [],
      // vCategoryIdForModal: '',
      // vQuestionIdForModal: '',
      // vChoiceIdForModal: '',

      // vPositionLeftForScroll: 0,
      // vPostionTopForScroll: 0,
      vFromAnswerList: false, // 回答一覧から修正するつもり判定
    };
  },
  created() {
    // 画面読み込み中・・・
    this.$store.state.common.sIsLoading = true;
    // ログイン状態判定
    this.doAuthLoginInfo();
    if (!this.$store.state.common.sIsLogined) {
      this.$router.push("/login");
    } else {
      // 画面タイトル
      this.$store.commit("common/doSetScreenTitle", {
        vScreenTitle: "",
      });
      // DB参照処理
      if (this.vComId && this.vEmpCode) {
        this.$axios
          .get(constant.cServerRoute.respondent.answer, {
            params: {
              pScStatusForScreen: this.$store.state.answer.sScStatusForScreen,
              pAnswerStep: this.vAnswerStep,
              pComId: this.vComId,
              pEmpCode: this.vEmpCode,
            },
          })
          .then(
            function (response) {
              console.log(response);
              if (
                response.data.return_result ==
                constant.cServerReturnResult.success
              ) {
                // どのページを表示するか分ける
                switch (this.$store.state.answer.sScStatusForScreen) {
                  // 準備中
                  case this.$store.state.answer.sAnswerScreen.preparation:
                    if (response.data.flg) {
                      this.flg = response.data.flg;
                      this.vScreenOnPreparation = {
                        sc_yearmonth: response.data.return_data.sc_yearmonth,
                        sc_answer_start_date:
                          response.data.return_data.sc_answer_start_date,
                        sc_answer_end_date:
                          response.data.return_data.sc_answer_end_date,
                        sc_conf_answer:
                          response.data.return_data.sc_conf_answer,
                        sc_conf_answer_name:
                          response.data.return_data.sc_conf_answer_name,
                        sc_conf_notice:
                          response.data.return_data.sc_conf_notice,
                        sc_conf_notice_name:
                          response.data.return_data.sc_conf_notice_name,
                      };
                    }
                    this.$store.commit("common/doEndServerProcess");
                    break;

                  // 実施中
                  case this.$store.state.answer.sAnswerScreen.ongoing:
                    if (response.data.flg) {
                      this.flg = response.data.flg;
                      this.vScreenOnGoing = {
                        sc_id: response.data.return_data.sc_id,
                        sc_yearmonth: response.data.return_data.sc_yearmonth,
                        sc_answer_end_date:
                          response.data.return_data.sc_answer_end_date,
                      };
                      this.vAnswerStatus =
                        response.data.return_data.answer_status;
                      this.vAnswerCompDate =
                        response.data.return_data.answer_comp_date;
                      this.vAnswerAllDone =
                        response.data.return_answer_all_done;
                    }
                    this.$store.commit("common/doEndServerProcess");
                    break;

                  // 実施済み
                  // case this.$store.state.answer.sScStatusForScreen.performed:
                  //   break;
                }
              } else {
                this.vMessage = response.data.return_message;
                this.$store.commit("common/doEndServerProcess");
              }
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error);
            this.vMessage = error;
            this.$store.commit("common/doEndServerProcess");
          });
      }
    }
  },
  // 画面読み込み終わった？
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "回答ページ";
  },
  computed: {},
  methods: {
    // 質問を表示する
    doDisplayAnswerStep2() {
      // DB参照処理
      this.vScId = this.vScreenOnGoing.sc_id;
      if (this.vComId && this.vEmpCode) {
        // ボタン制御
        this.$store.commit("common/doStartServerProcess");
        this.vMessage = "";
        this.$axios
          .get(constant.cServerRoute.respondent.answer, {
            params: {
              pScStatusForScreen: this.$store.state.answer.sScStatusForScreen,
              // pAnswerStep: this.vAnswerStep,
              pAnswerStep: this.$store.state.answer.sAnswerStep.question,
              pComId: this.vComId,
              pEmpCode: this.vEmpCode,
              pScId: this.vScreenOnGoing.sc_id,
            },
          })
          .then(
            function (response) {
              console.log(response);
              // アンケート情報参照処理判定
              if (
                response.data.return_result ==
                constant.cServerReturnResult.success
              ) {
                // 選択肢情報を保持する
                if (response.data.return_data_choice) {
                  this.vChoicePatternListData =
                    response.data.return_data_choice;
                }
                // 質問情報を保持する
                if (response.data.return_data_question) {
                  this.vQuestionListData = response.data.return_data_question;
                  // 質問リストを整形する
                  this.doCreateQuestionForDisplay(
                    this.$store.state.answer.sCreateQuestionSituation.first
                  );
                  if (this.vAnswerAllDone) {
                    this.vAnswerStep =
                      this.$store.state.answer.sAnswerStep.result;
                  } else {
                    this.vAnswerStep =
                      this.$store.state.answer.sAnswerStep.question;
                  }
                }
                // ボタン制御
                this.$store.commit("common/doEndServerProcess");
              } else {
                this.vMessage = response.data.return_message;
                // ボタン制御
                this.$store.commit("common/doEndServerProcess");
              }
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error);
            this.vMessage = error;
            // ボタン制御
            this.$store.commit("common/doEndServerProcess");
          });
      }
    },

    // 質問情報を整形する
    doCreateQuestionForDisplay(situation = null) {
      // カテゴリ
      var tmpArrayCategory = this.vQuestionListData.filter(
        (a) =>
          a.question_type == this.$store.state.answer.sQuestionType.category
      );
      // 質問
      var tmpArrayQuestion = this.vQuestionListData.filter(
        (a) =>
          a.question_type == this.$store.state.answer.sQuestionType.question
      );

      // 回答状況確認用
      this.vCategoryDataForResult = this.vQuestionListData.filter(
        (a) =>
          a.question_type == this.$store.state.answer.sQuestionType.category
      );
      this.vQuestionDataForResult = this.vQuestionListData.filter(
        (a) =>
          a.question_type == this.$store.state.answer.sQuestionType.question
      );

      switch (situation) {
        // 初期表示
        case this.$store.state.answer.sCreateQuestionSituation.first:
          if (tmpArrayQuestion.length > 0) {
            // 最終到達質問情報を取得しておく
            this.doGetLatestQuestionInfo(tmpArrayQuestion);
            this.vCategoryId = this.vLatestCategoryId;
            this.vQuestionId = this.vLatestQuestionId;
            // 質問抽出
            this.vQuestionDataForAnswer = tmpArrayQuestion.filter(
              (a) => a.category_id == this.vCategoryId
            );
          }
          break;

        // 次の質問へ進む
        case this.$store.state.answer.sCreateQuestionSituation.next:
          if (!this.vFromAnswerList) {
            // 最終到達質問情報を取得しておく
            this.doGetLatestQuestionInfo(tmpArrayQuestion);
            // 最終問題の場合は回答一覧確認画面を表示
            console.log("Index:" + this.vQuestionIndex);
            console.log("Question_length" + tmpArrayQuestion.length);
            if (this.vQuestionIndex + 1 == tmpArrayQuestion.length) {
              console.log("最終問題に解答");
              this.vCategoryId = this.vLatestCategoryId;
              this.vQuestionId = this.vLatestQuestionId;
              // this.vAnswerStep = this.$store.state.answer.sAnswerStep.result;
              this.vAnswerAllDone = true;
            } else {
              if (
                this.vCategoryId == this.$store.state.answer.sCategoryType.d
              ) {
                this.vQuestionId += 1;
              } else {
                // 質問抽出
                this.vQuestionDataForAnswer = tmpArrayQuestion.filter(
                  (a) => a.category_id == this.vCategoryId
                );
                if (this.vQuestionId == this.vQuestionDataForAnswer.length) {
                  this.vCategoryId += 1;
                  this.vQuestionId = 1;
                  moreFilter = true;
                } else {
                  this.vQuestionId += 1;
                }
                this.vAnswerStatus = 1;
              }
            }
          }
          // 質問抽出
          this.vQuestionDataForAnswer = tmpArrayQuestion.filter(
            (a) => a.category_id == this.vCategoryId
          );
          break;

        // 質問1つ戻る
        case this.$store.state.answer.sCreateQuestionSituation.previous:
          var moreFilter = false;
          if (this.vCategoryId == this.$store.state.answer.sCategoryType.a) {
            this.vQuestionId -= 1;
          } else {
            if (this.vQuestionId == 1) {
              this.vCategoryId -= 1;
              moreFilter = true;
            } else {
              this.vQuestionId -= 1;
            }
          }
          // 質問抽出
          this.vQuestionDataForAnswer = tmpArrayQuestion.filter(
            (a) => a.category_id == this.vCategoryId
          );
          if (moreFilter) {
            this.vQuestionId = this.vQuestionDataForAnswer.length;
          }
          break;

        default:
          this.vQuestionDataForAnswer = tmpArrayQuestion.filter(
            (a) => a.category_id == this.vCategoryId
          );
          break;
      }
      // カテゴリ抽出
      this.vCategoryDataForAnswer = tmpArrayCategory.filter(
        (a) => a.category_id == this.vCategoryId
      );
      // 質問抽出
      this.vQuestionDataForAnswer = this.vQuestionDataForAnswer.filter(
        (a) => a.question_id == this.vQuestionId
      );
      // 選択肢抽出
      this.vChoicePatternForDisplay = this.vChoicePatternListData.filter(
        (a) => a.category_id == this.vCategoryId
      );
      // 現在表示している質問index
      // this.vQuestionIndex = this.vQuestionDataForResult.findIndex((a)=> a.category_id == this.vCategoryId && a.question_id == this.vQuestionId);
      this.vQuestionIndex = this.vQuestionDataForResult.filter(
        (a) =>
          a.category_id == this.vCategoryId && a.question_id == this.vQuestionId
      )[0].question_index;
    },
    // 最終到達質問情報取得
    doGetLatestQuestionInfo(array) {
      var i = 0;
      var tmpQuestionIndex = -1;
      for (i = 0; i < array.length; i++) {
        if (!array[i].choice_id) {
          // 未回答部分から始める
          this.vLatestCategoryId = array[i].category_id;
          this.vLatestQuestionId = array[i].question_id;
          // this.vLatestQuestionIndex = i;
          tmpQuestionIndex = i;
          break;
        } else {
          this.vLatestCategoryId = array[i].category_id;
          this.vLatestQuestionId = array[i].question_id;
          this.vLatestQuestionIndex = i;
          if (i + 1 == array.length) {
            // 全問回答済み
            this.vAnswerAllDone = true;
            this.vAnswerStep = this.$store.state.answer.sAnswerStep.result;
          }
        }
      }
      this.vLatestQuestionIndex = tmpQuestionIndex;
    },
    // 次の質問を表示する
    doChoiceAnswer(index) {
      // ボタン制御
      this.$store.commit("common/doStartServerProcess");
      (this.vChoiceId = index + 1), (this.vChoiceIndex = index); // 選択肢ボタンの外観制御のためにセット
      // DB更新
      this.$axios
        .post(constant.cServerRoute.respondent.answer, {
          pComId: this.vComId,
          pScId: this.vScId,
          pEmpCode: this.vEmpCode,
          pCategoryId: this.vCategoryId,
          pQuestionId: this.vQuestionId,
          pChoiceId: this.vChoiceId,
        })
        .then(
          function (response) {
            console.log(response);
            // 回答子データ更新処理判定
            if (
              response.data.return_result ==
              constant.cServerReturnResult.success
            ) {
              // 質問情報を保持する
              if (response.data.return_data_question) {
                this.vQuestionListData = response.data.return_data_question;
                // ボタン制御
                this.$store.commit("common/doEndServerProcess");
                // 次の質問indexを設定、選択肢をリセット
                window.setTimeout(() => {
                  this.doCreateQuestionForDisplay(
                    this.$store.state.answer.sCreateQuestionSituation.next
                  );
                  // 全問回答済み状態（回答一覧からの修正）
                  if (this.vFromAnswerList) {
                    this.vAnswerStep =
                      this.$store.state.answer.sAnswerStep.result;
                    // 回答一覧の元の位置までスクロールさせる
                    this.doScrollWindowIntoView();
                  } else {
                    if (this.vAnswerAllDone) {
                      this.doScrollWindowToTop();
                    }
                  }
                  this.vChoiceIndex = -1;
                }, 600);
              }
              // 値クリアしておく
              // this.doClear();
            } else {
              this.vMessage = response.data.return_message;
              // ボタン制御
              this.$store.commit("common/doEndServerProcess");
            }
          }.bind(this)
        )
        .catch(function (error) {
          // 更新メッセージ表示
          this.$store.commit("common/doNotificationUpdate", {
            vMode: 1,
          });
          console.log(error);
          this.vMessage = error;
          // ボタン制御
          this.$store.commit("common/doEndServerProcess");
        });
    },
    // 質問を1つ戻る
    doBackToPreviousQuestion() {
      if (
        this.vCategoryId >= this.$store.state.answer.sCategoryType.b ||
        (this.vCategoryId == this.$store.state.answer.sCategoryType.a &&
          this.vQuestionId > 1)
      ) {
        this.doCreateQuestionForDisplay(
          this.$store.state.answer.sCreateQuestionSituation.previous
        );
      }
    },
    // 回答を提出する
    doSubmitAnswer() {
      // ボタン制御
      this.$store.commit("common/doStartServerProcess");
      // DB更新
      this.$axios
        .post(constant.cServerRoute.respondent.answer_submit, {
          pComId: this.vComId,
          pScId: this.vScId,
          pEmpCode: this.vEmpCode,
        })
        .then(
          function (response) {
            console.log(response);
            // 回答親データ更新処理判定
            if (
              response.data.return_result ==
              constant.cServerReturnResult.success
            ) {
              // 質問情報を保持する
              if (response.data.return_data_question) {
                this.vQuestionListData = response.data.return_data_question;
                // 次の質問indexを設定、選択肢をリセット
                window.setTimeout(() => {
                  this.doCreateQuestionForDisplay(
                    this.$store.state.answer.sCreateQuestionSituation.next
                  );
                  this.vChoiceIndex = -1;
                  this.vAnswerStep = 0;
                  this.vAfterSubmit = true;
                }, 600);
              }
              // ボタン制御
              this.$store.commit("common/doEndServerProcess");
              // 値クリアしておく
              // this.doClear();
            } else {
              this.vMessage = response.data.return_message;
              // ボタン制御
              this.$store.commit("common/doEndServerProcess");
            }
          }.bind(this)
        )
        .catch(function (error) {
          // 更新メッセージ表示
          this.$store.commit("common/doNotificationUpdate", {
            vMode: 1,
          });
          console.log(error);
          this.vMessage = error;
          // ボタン制御
          this.$store.commit("common/doEndServerProcess");
        });
    },
    // 回答ページの切替（質問選択 / 回答一覧確認）
    doSwitchDisplayMode() {
      if (this.vAnswerStep == this.$store.state.answer.sAnswerStep.question) {
        this.vAnswerStep = this.$store.state.answer.sAnswerStep.result;
      } else {
        this.vAnswerStep = this.$store.state.answer.sAnswerStep.question;
      }
    },
    // 回答一覧から回答を修正
    doReChoiceAtAnswerList(categoryId, questionId) {
      this.vFromAnswerList = true;
      this.vCategoryId = categoryId;
      this.vQuestionId = questionId;
      this.doCreateQuestionForDisplay();
      this.vAnswerStep = this.$store.state.answer.sAnswerStep.question;

      // // 質問リストを抽出
      // this.vQuestionDataForModal = this.vQuestionListData.filter((a)=> a.question_type == this.$store.state.answer.sQuestionType.question);
      // this.vQuestionDataForModal = this.vQuestionDataForModal.filter((a)=> (a.category_id == categoryId && a.question_id == questionId));
      // // 選択肢リストを抽出
      // this.vChoicePatternForModal = this.vChoicePatternListData.filter((a)=> a.category_id == categoryId);
      // // モーダル用カテゴリID、質問IDを保持する
      // this.vCategoryIdForModal = categoryId;
      // this.vQuestionIdForModal = questionId;
      // console.log('------------------');
      // console.log('this.vCategoryIdForModal = ', this.vCategoryIdForModal);
      // console.log('this.vQuestionIdForModal = ', this.vQuestionIdForModal);
      // // モーダル表示
      // this.doConfirmUpdate(this.vModalIdForAnswer);
    },
    // // 回答一覧で回答を選択
    // doChoiceAnswerAtModal(choiceId) {
    //   // ボタン制御
    //   this.$store.commit('common/doStartServerProcess');
    //   console.log('-------------------');
    //   console.log('this.vCategoryIdForModal = ', this.vCategoryIdForModal);
    //   console.log('this.vQuestionIdForModal = ', this.vQuestionIdForModal);
    //   this.vChoiceIdForModal = choiceId;
    //   console.log('this.vChoiceIdForModal = ', this.vChoiceIdForModal);
    //   // DB更新
    //   this.$axios.post(constant.cServerRoute.respondent.answer, {
    //     pComId: this.vComId,
    //     pScId: this.vScId,
    //     pEmpCode: this.vEmpCode,
    //     pCategoryId: this.vCategoryIdForModal,
    //     pQuestionId: this.vQuestionIdForModal,
    //     pChoiceId: this.vChoiceIdForModal
    //   })
    //     .then(function(response){
    //       console.log(response);
    //       // 回答子データ更新処理判定
    //       if (response.data.return_result == constant.cServerReturnResult.success) {
    //         // 質問情報を保持する
    //         if (response.data.return_data_question) {
    //           this.vQuestionListData = response.data.return_data_question;
    //           // 質問リストを最新状態にする
    //           // this.vQuestionDataForAnswer = this.vQuestionListData.filter((a)=> a.question_type == this.$store.state.answer.sQuestionType.question);
    //           // this.vQuestionDataForResult = this.vQuestionListData.filter((a)=> a.question_type == this.$store.state.answer.sQuestionType.question);
    //           // 質問リストを最新状態にする
    //           this.vCategoryId = this.vCategoryIdForModal;
    //           this.vQuestionId = this.vQuestionIdForModal;
    //           this.doCreateQuestionForDisplay(this.$store.state.answer.sCreateQuestionSituation.next);
    //           // // 次の質問indexを設定、選択肢をリセット
    //           // window.setTimeout(()=> {
    //           //   // ボタン制御
    //           //   this.$store.commit('common/doEndServerProcess');
    //           //   this.doCreateQuestionForDisplay(this.$store.state.answer.sCreateQuestionSituation.next);
    //           //   this.vChoiceIndex = -1;
    //           // }, 600)
    //           // ボタン制御
    //           this.$store.commit('common/doEndServerProcess');
    //           // モーダル閉じる
    //           this.doHideModal(this.vModalIdForAnswer);
    //         }
    //         // 値クリアしておく
    //         // this.doClear();
    //       } else {
    //         this.vMessage = response.data.return_message;
    //         // ボタン制御
    //         this.$store.commit('common/doEndServerProcess');
    //         // モーダル閉じる
    //         this.doHideModal(this.vModalIdForAnswer);
    //       }
    //     }.bind(this))
    //     .catch(function(error){
    //       // 更新メッセージ表示
    //       this.$store.commit('common/doNotificationUpdate', {
    //         'vMode': 1
    //       });
    //       console.log(error);
    //       this.vMessage = error;
    //       // ボタン制御
    //       this.$store.commit('common/doEndServerProcess');
    //       // モーダル閉じる
    //       this.doHideModal(this.vModalIdForAnswer);
    //     })
    // },
    // 回答ページの表示切替
    doChangeAnswerStep(step) {
      this.vAnswerStep = step;
    },
    // 画面スクロールする
    doScrollWindowIntoView() {
      window.setTimeout(() => {
        const elId = this.vCategoryId + "_" + this.vQuestionId;
        document.getElementById(elId).scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        this.vFromAnswerList = false;
      }, 50);
    },
    // 画面のトップへスクロールする
    doScrollWindowToTop() {
      window.scrollTo({
        left: 0,
        top: 0,
      });
    },
    // DB更新前確認処理
    doConfirmUpdate(modalId) {
      UIkit.modal("#" + modalId).toggle();
    },
    // モーダル閉じる
    doHideModal(modalId) {
      UIkit.modal("#" + modalId).hide();
    },
    // 回答者ホームへ
    doGoRespondentHome() {
      this.$router.replace("/respondent-home");
    },
    // ID,パスワード照合
    doAuthLoginInfo() {
      if (sessionStorage.getItem("storageId")) {
        let createHash = require("sha256-uint8array").createHash;
        let tmp = createHash()
          .update(sessionStorage.getItem("storagePw"))
          .digest("hex");
        this.$axios
          .post(constant.cServerRoute.common.login, {
            alias_id: sessionStorage.getItem("storageAliasId"),
            pLoginId: sessionStorage.getItem("storageId"),
            pLoginPw: tmp,
          })
          .then(
            function (response) {
              console.log(response);
              if (
                response.data.return_result !=
                constant.cServerReturnResult.success
              ) {
                this.$store.state.common.sIsLogined = false;
              }
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error);
            this.$store.state.common.sIsLogined = false;
          });
      }
    },
  },
  // ページを離れる
  beforeRouteLeave() {
    this.$store.state.answer.sScId = "";
    this.$store.state.answer.sScStatusForScreen = 0;
    // UIkit.modal('#' + this.vModalIdForAnswer).$destroy(true);
  },
};
</script>
<style scoped>
.modal {
  padding: 10px 20px;
  border: 2px solid #00a2ff;
  background: #ffffff;
  z-index: 2;
  display: block;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.modal__message {
  margin-top: 10px;
}

#modal-overlay {
  z-index: 1;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background-color: rgba(0, 0, 0, 0.75);
}

input {
  font-size: 20px;
  padding: 0.2em 0.3em;
  border-radius: 3px;
  border: none;
  background: gainsboro;
}
</style>
