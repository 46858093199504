// 定数

export default {
  namespaced: true,
  cServerReturnResult: {  // サーバーの処理結果
    success: 0, // 処理成功
    failure: 1  // 処理失敗
  },
  cUserType: {  // ユーザー種別
    intelligent: 1, // インテリジェント担当者
    customer: 2,    // クライアント担当者
    respondent: 3   // 回答者
  },
  cNotificationUpdateMode: {  // UIKit Notification 表示メッセージ
    success: 0, // 処理成功
    failure: 1  // 処理失敗
  },
  cPageOperationMode: { // 画面操作モード
    nothing: 0, // なし
    add: 1,     // 追加
    change: 2,  // 変更
    delete: 3   // 削除
  },
  cChangeRowColor: {  // 選択行の色
    nothing: 0, // なし
    coloring: 1 // 色をつける
  },
  cConfAnswer: {  // 回答形式
    paper: 1, // マークシート
    web: 2,   // Web
    both: 3   // 両方
  },
  cConfNotice: {  // 結果通知形式
    paper: 1, // ストレスシート
    web: 2,   // Web
    both: 3   // 両方
  },
  cGroupAnalysis: {  // 集団分析の実施
    not: 0, // 不要
    do: 1,  // 必要
  },
  cScConfForCalc: {  // ストレスチェックの人数や単価計算用
    all: 0,   // 全体
    paper: 1, // 紙
    web: 2,   // Web
  },
  cScConfForCalcText: {  // ストレスチェックの人数や単価計算用（文字列で）
    answer: 'answer', // 回答
    notice: 'notice', // 結果通知
  },
  cServerRoute: { // サーバーの接続先
    common: {
      login: '/login',
      first_registration: '/first-registration'
    },
    intelligent: {
      home: '/intelligent-home',
      ocr_result_capture: '/ocr-result-capture',
      analysis: '/analysis'
    },
    customer: {
      home: '/customre-home',
      company: '/company',
      workplace: '/workplace',
      employee: '/employee',
      employee_file_download: '/employee-file-download',
      employee_file_upload: '/employee-file-upload',
      employee_create_bulk: '/employee-create-bulk',
      sc_config: '/sc-config',
      sc_config_request: '/sc-request',
    },
    respondent: {
      home: '/respondent-home',
      answer: '/answer',
      answer_submit: '/answer-submit'
    }
  },
  cPageId: {  // VueのページID（主にエラーログ用）
    common_FIRSTREGISTRATION: 1,
    common_LOGIN: 2,
    intelligent_HOME: 11,
    intelligent_ocr_result_capture: 12,
    intelligent_analysis: 13,
    customer_HOME: 21,
    customer_COMPANY: 22,
    customer_WORKPLACE: 23,
    customer_EMPLOYEE: 24,
    customer_SC_CONFIG: 25,
    respondent_HOME: 31,
    respondent_ANSWER: 32,
  }

}
